import { Strings } from "@/resources";
import { Link } from "@inertiajs/react";
import slick_arrow_icon from "../../images/slick-arrow.svg";
import { useState } from "react";

const language = Strings.footer;

export default function GuestFooter({ ziggy }: { ziggy: any }) {
    const [isPatVisible, setIsPatVisible] = useState<boolean>(false);

    const togglePatNumber = (
        e:
            | React.MouseEvent<HTMLButtonElement, MouseEvent>
            | React.KeyboardEvent<HTMLButtonElement>,
    ) => {
        e.preventDefault();
        setIsPatVisible(!isPatVisible);
    };

    return (
        <footer>
            <div className="container">
                <div className="footer-content">
                    <div className="copyrights">
                        <div className="flex-wrapper">
                            <p>
                                {" "}
                                &copy; {new Date().getFullYear()}{" "}
                                {language.copyright_text}
                            </p>
                            {/* toggle "rotate" class when click on button */}
                            <button
                                className={`dropdown-btn ${isPatVisible ? "rotate" : ""}`}
                                onClick={(e) => {
                                    togglePatNumber(e);
                                }}
                            >
                                <img
                                    src={slick_arrow_icon}
                                    alt="arrow"
                                    className="img-fluid"
                                />
                            </button>
                        </div>
                        {/* toggle "show" & "hide" class according to requirement */}
                        <p
                            className={`pat-txt ${isPatVisible ? "show" : "hide"}`}
                        >
                            {language.pat_no_text}
                        </p>
                    </div>
                    <div className="footer-menu">
                        <Link
                            href={route("cms-page.about", {
                                clinic: ziggy.clinic,
                            })}
                        >
                            {language.about}
                        </Link>
                        <Link
                            href={route("cms-page.security-statement", {
                                clinic: ziggy.clinic,
                            })}
                        >
                            {language.security_statement}
                        </Link>
                        <Link
                            href={route("cms-page.privacy", {
                                clinic: ziggy.clinic,
                            })}
                        >
                            {language.privacy_policy}
                        </Link>
                        <Link
                            href={route("cms-page.terms", {
                                clinic: ziggy.clinic,
                            })}
                        >
                            {language.terms_conditions}
                        </Link>
                        {/* <Link
                            href={route("cms-page.contact", {
                                clinic: ziggy.clinic,
                            })}
                        >
                            {language.contact}
                        </Link> */}
                        <Link
                            href={route("cms-page.faq", {
                                clinic: ziggy.clinic,
                            })}
                        >
                            {language.faqs}
                        </Link>
                        <Link
                            href={route("cms-page.patent", {
                                clinic: ziggy.clinic,
                            })}
                        >
                            {language.patent_information_text}
                        </Link>
                    </div>
                </div>
            </div>
        </footer>
    );
}

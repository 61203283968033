import { PropsWithChildren } from "react";
import GuestFooter from "./GuestFooter";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Guest({
    children,
    ziggy,
}: PropsWithChildren<{ ziggy?: any }>) {
    return (
        <>
            {children}
            <GuestFooter ziggy={ziggy} />
            <ToastContainer theme="colored" autoClose={7000} />
        </>
    );
}
